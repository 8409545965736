<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="custom-service-modal">
      <v-card-title>
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <IconPictoAarrowsLeft />
        </v-btn>
        <span class="custom-service-modal__title">Créer une prestation</span>
        <v-btn fab dark color="info" x-small text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="custom-service-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form-custom-service"
          >
            <div class="form__body">
              <v-text-field
                v-model="formData.name"
                outlined
                label="Nom de la prestation*"
                type="text"
                persistent-placeholder
                dense
                placeholder="Installation  veranda"
                :rules="[validations.required]"
              ></v-text-field>
              <v-textarea
                v-model="formData.description"
                outlined
                label="Description"
                type="text"
                persistent-placeholder
                dense
                placeholder="Faîtes une description de la prestation"
              ></v-textarea>
              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                  :loading="loading"
                >
                  CREER LA PRESTATION
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
import { ProjectService } from "@/services/project.service.js";
import {
  CustomRoomServicesService,
  RoomServicesService,
} from "@/services/roomServices.sevice.js";

export default {
  name: "AddCustomServiceModal",
  props: {
    show: {
      type: Boolean,
    },
    roomId: {
      type: Number,
      reaquired: true,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      formData: {
        name: "",
        description: "",
      },
      valid: true,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
    model: {
      immediate: true,
      handler(newVal) {
        this.formData = Object.assign({}, newVal);
      },
    },
  },
  methods: {
    submit() {
      this.$refs["form-custom-service"].validate();
      if (!this.valid) return;
      this.loading = true;
      CustomRoomServicesService.createService({
        ...this.formData,
        owner_id: this.$store.getters["project/getCurrentProjectOwnerId"],
      })
        .then(({ data }) => {
          this.createRoomService(data.id).finally(() => {
            this.$emit("createCustomService", data);
            this.$emit("closeModal");
          });
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loading = false));
    },
    createRoomService(serviceId) {
      return RoomServicesService.createQuotaRoomService({
        quota_room_id: this.roomId,
        service_id: serviceId,
        amount: 1,
      }).catch((ex) => console.log(ex));
    },
  },
};
</script>

<style src="./CustomServiceModal.scss" lang="scss" scoped></style>